<template>
  <v-row style="margin-left: 0px;margin-right: 0px">
    <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
    <v-col xs="12" sm="10" v-if="chefselected">
      <div >
        <v-row class="theme--dark" style="color:white;margin-left: 0px;margin-right: 0px" >
          <v-col align="start" justify="center" >
            <v-row align-content="center" align="center" >
              <v-col align="start" class="col-auto">
                <v-avatar v-if="chefselected.profile.photoPath && chefselected.profile.photoFilename" size="64" @click="$refs.aboutChef.scrollIntoView({ behavior: 'smooth'})" style="cursor: pointer">
                  <img style="object-fit: cover;"
                       :src="chefselected.profile.photoPath +'low.'+ chefselected.profile.photoFilename"
                       :alt="chefselected.profile.profileName"
                  />
                </v-avatar>
                <v-avatar align="start" v-else style="margin-right: 10px;background-color: white;">
                  <img   style="object-fit: cover;"
                         src="../assets/logo.png"
                  />
                </v-avatar>
              </v-col>
              <v-col align-self="center" style="padding-top: 0px;padding-bottom: 0px;cursor: pointer" @click="$refs.aboutChef.scrollIntoView({ behavior: 'smooth'})" >
                <div class="flex-row" style="color:white;white-space: nowrap;font-size: x-large;">
                  {{ chefselected.profile.profileName }}
                </div>
                <!--                                    <div class="flex-row" style="white-space: nowrap;font-size: large;"> {{chefselected.location }}</div>-->
                <a @click="$refs.aboutChef.scrollIntoView({ behavior: 'smooth'})" class=" v-application primary--text flex-row" style="text-decoration:underline;white-space: nowrap;font-size: small;"> about the chef
                </a>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" align="end" align-self="center">
            <!--                          v-model="chefselected.rating"-->
            <star-rating  read-only :increment="0.5" v-model="rating" :star-size="15" align="end"
                          align-self="center" inline></star-rating>
          </v-col>
        </v-row>
        <!--                <v-row style="margin-left: 12px;margin-right: 12px" align-content="center" align="center"-->
        <!--                       justify="center">-->
        <!--                    <a href="javascript:void(0)" @click="toggleChef">-->
        <!--                        {{aboutChef?"less":"more"}} about the chef-->
        <!--                    </a>-->
        <!--                </v-row>-->
        <services :chefselected="chefselected" :first-service="serviceOrdered[0]"></services>
      </div>

      <v-divider style="margin-top: 12px;margin-bottom: 12px;" dark></v-divider>
      <v-dialog v-model="gallery" fullscreen hide-overlay transition="dialog-bottom-transition">
        <!--            <template v-slot:activator="{ on }">-->
        <!--                <v-btn color="primary" light v-on="on">Open Dialog</v-btn>-->
        <!--            </template>-->
        <v-card>
          <v-btn icon @click="gallery = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="gallery">
            <div
                v-for="(photo, index) in  chefselected.photos"
                :key="index"
                class="pic"
                @click="photoDetails=true;photoIndex=index;$log(photoIndex)"
            >
              <!--                                @click="() => showImg(index)">-->
              <img :src="photo.photoPath+'medium.'+photo.photoFilename" class="gallery-pic">
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="photoDetails" fullscreen content-class="caroussel-dialog-fullscreen" hide-overlay>
        <!--            <template v-slot:activator="{ on }">-->
        <!--                <v-btn color="primary" light v-on="on">Open Dialog</v-btn>-->
        <!--            </template>-->
        <v-btn icon dark @click="photoDetails = false" style="position: absolute;z-index: 1;">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-carousel v-if="chefselected"
                    show-arrows
                    hide-delimiters
                    class="caroussel-fullscreen"
                    v-model="photoIndex"
                    height="100%"
        >
          <v-carousel-item contain
                           v-for="(photo,index) in chefselected.photos"
                           :key="index"
                           :src="photo.photoPath+'high.'+photo.photoFilename"
          ></v-carousel-item>
        </v-carousel>
      </v-dialog>
      <v-carousel
          show-arrows
          hide-delimiters
          class="caroussel"
          height="250px"
          style="margin-top: 12px"
      >
        <v-carousel-item
            v-for="(item, i) in chefselected.photos"
            :key="i"
            :src="item.photoPath+'medium.'+item.photoFilename"
        ></v-carousel-item>
        <v-row class="rowGalleryMenuOverlay " align="end" justify="end" no-gutters style="padding: 0px">
          <v-container>
            <v-btn outlined @click="gallery = true" class="btn-black-30">
              Gallery
            </v-btn>
          </v-container>
        </v-row>
      </v-carousel>

      <div id="aboutChef" ref="aboutChef" style="color: white;">
        <about-chef :chef="chefselected" ></about-chef>
      </div>

    </v-col>
    <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
  </v-row>
</template>
<script>
import StarRating from 'vue-star-rating'
import AboutChef from '../components/AboutChef';
import Vue from "vue";
import Services from "@/components/Services.vue";
Vue.component('star-rating', StarRating);
Vue.component('about-chef', AboutChef);
Vue.component('services', Services);
export default {
  name: "Profile",
  props: {
    chefselected: Object,
    firstService: Object,
    closeAction: Function,
  },

  data() {
    return {
      rating:5,
      photoIndex: 0,
      index: null,
      gallery: false,
      photoDetails: false,
    };
  },
  mounted() {
  },
  methods: {
    filteredDishesByCategory(service, category) {
      if (service !== undefined && category !== undefined) {
        return service.dishes.filter(d => d.itemCategory !== undefined && d.itemCategory.name === category.name)
      } else {
        return [];
      }
    },
    book(serviceType,chefSelected){
      this.closeAction();
      this.$root.serviceSelected=serviceType;
      this.$root.chefSelected=chefSelected;
      this.$root.dishesSelected=[];
      this.$router.push({path: `/book`});
    },
  },
  computed: {
    serviceOrdered(){
      let result=[];
      console.log(this.firstService);
      let firstService=this.firstService;
      result.push(firstService);
      this.chefselected.service.forEach( function(item){
            if (item.id!==firstService.id){
              result.push(item);
            }
          }
      );
      return result
    }
  }
}
</script>