<template>
  <div class="chefSearch">
    <v-card class="chefCard"  >
      <v-carousel
          :show-arrows="servicePhotos.length>0"
          hide-delimiters
          class="caroussel"
          height="250px"

      >
        <div v-if="servicePhotos.length>0">
          <v-carousel-item
              v-for="(item, i) in servicePhotos"
              :key="i"
              :src="item.photoPath+'medium.'+item.photoFilename"
          ></v-carousel-item>
        </div>
        <div v-else>
          <v-img   style="object-fit: cover;"
                   :src="servicePhotoDefault"
                   lazy-src="../assets/logo.png"
          />
        </div>

        <div class="container chefSearchTop">
          <v-row no-gutters>
            <v-col align="start"  style="padding: 0px" class="col-auto">
              <v-avatar align="start" v-if="chefService.chef.profile && chefService.chef.profile.photoPath && chefService.chef.profile.photoFilename" style="margin-right: 10px">
                <img   style="object-fit: cover;"
                       v-if="chefService.chef.profile && chefService.chef.profile.photoPath && chefService.chef.profile.photoFilename"
                       :alt="chefService.chef.profile.profileName"
                       :src="chefService.chef.profile.photoPath+'low.'+chefService.chef.profile.photoFilename"
                       lazy-src="../assets/logo.png"
                />
              </v-avatar>
              <v-avatar align="start" v-else style="margin-right: 10px;background-color: white;">
                <img   style="object-fit: cover;"
                       src="../assets/logo.png"
                />
              </v-avatar>
            </v-col>
            <v-col style="padding: 0px;white-space: nowrap;" align-self="center" justify="start" align="start" v-if="chefService.chef.profile">
              {{ chefService.chef.profile.profileName }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col align="center" style="padding: 0;text-shadow: 1px 1px rgba(0, 0, 0, .75);}" >{{chefService.serviceType.displayName}}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col align="center" style="padding: 0;text-shadow: 1px 1px rgba(0, 0, 0, .75);}" >
              <v-btn outlined @click="$emit('openMenu')" class="btn-black-30">Menu</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-carousel>
      <v-row style="text-align: left" no-gutters wrap align="center">
        <v-col justify="start" cols="auto">
          <div v-if="averagePrice" style="    margin: 0 12px 0 12px;">Average dish price: ${{ averagePrice }}</div>
          <div v-if="lowestPackagePrice" style="   margin: 0 12px 0 12px;">Packages from: ${{ lowestPackagePrice }}</div>
        </v-col>
        <v-col class="margin-default" cols="" style="text-align: end">
          <v-btn class="book "
                 @click="$emit('book', chefService.serviceType, chefService.chef)"
                 outlined
          >{{chefService.available?"Book":"Request a Date"}}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ChefCard",
  props: {
    chefService: {
      type: Object,
      required: true
    }
  },
  computed: {
    servicePhotos() {
      let photos =[];
      if (this.chefService!==undefined && this.chefService.chef.photos!==undefined){
        photos = this.chefService.chef.photos.filter(photo=>( photo.serviceType.name===this.chefService.serviceType.name));
      }
      return photos;
    },
    servicePhotoDefault() {
      if (this.chefService.serviceType.name==='CLASSIC'){
        return '/img/course.jpg';
      }else if (this.chefService.serviceType.name==='SHARING'){
        return '/img/sharing.jpg';
      }else if (this.chefService.serviceType.name==='FUNCTION'){
        return '/img/cocktail.jpg';
      }else if (this.chefService.serviceType.name==='GRAZING'){
        return '/img/grazing.jpg';
      }
      return '/img/course.jpg';
    },
    averagePrice() {
      const service = this.chefService;
      if ( service!==undefined && service.enabled && service.dishes!==undefined){
        let dishes=service.dishes.filter(d => (d.itemCategory.categoryType.name!=="KID"));
        let total=0;
        for(let i = 0; i < dishes.length; i++) {
          total += dishes[i].price;
        }
        let average = total / service.dishes.length;
        return this.roundToXDigits(average,2);
      }
      return undefined;
    },
    lowestPackagePrice() {
      const service = this.chefService;
      if ( service!==undefined && service.packages!==undefined && service.packages.length>0){
        let packs=service.packages.filter(p => (p.enabled));
        let cheapest = packs.sort(function (a, b) {
          return a.price - b.price;
        })[0];
        if (cheapest!==undefined){
          return this.roundToXDigits(cheapest.price,2);
        }

      }
      return undefined;
    },
  },
  methods: {
    roundToXDigits(value, digits) {
      return parseFloat(value.toFixed(digits))
    }
  }
};
</script>
